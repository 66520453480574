<template>
  <b-row>
    <b-col cols="12" v-if="$can('create', 'section')">
      <b-button class="mb-1" variant="primary" @click="addSectionModal = true">Добавить</b-button>
    </b-col>
    <b-col cols="12">
      <b-table :fields="[{key: 'name', label: 'Наименование'}, {key: 'kind_label', label: 'Разновидность'}, {key: 'actions', label: 'Действия'}]" :items="sections">
        <template #cell(actions)="row">
          <b-icon-trash @click="deleteSection(row.item.id)" variant="danger" class="cursor-pointer"></b-icon-trash>
        </template>
      </b-table>
    </b-col>
    <b-modal title="Добавить секцию" v-model="addSectionModal">
      <b-form-group
        label="Наименование"
        label-cols-sm="4"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="7"
      >
        <b-form-input placeholder="Веб-разработка с Online Solutions" v-model="sectionName" />
      </b-form-group>
      <b-form-group
        label="Разновидность"
      >
        <b-form-radio-group
          v-model="sectionKind"
          name="kind"
        >
          <b-form-radio value="common">
            Клуб
          </b-form-radio>
          <b-form-radio value="facultative">
            Занятие
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="outline-danger" @click="addSectionModal = false">Отмена</b-button>
        <b-button variant="success" @click="saveData">Сохранить</b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {BIconTrash, BTable, BModal, BFormGroup, BCol, BRow, BButton, BFormInput, BFormRadioGroup, BFormRadio} from 'bootstrap-vue'

export default {
  name: 'ListSection',
  components: {
    BTable, BModal, BFormGroup, BCol, BRow, BButton, BFormInput, BIconTrash,
    BFormRadioGroup, BFormRadio,
  },
  props: {
    kind: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      addSectionModal: false,
      sectionName: '',
      sectionKind: 'common',
    }
  },
  computed: {
    sections() {
      return this.$store.state.info.sections
    },
  },
  methods: {
    deleteSection(id) {
      this.$store.dispatch('info/deleteSection', id)
          .then(() => {
            this.$toast.success('Секция удалена')
          }).catch(() => {
        this.$toast.error('Не удалось удалить секцию')
      })
    },
    fetchData() {
      this.$store.dispatch('info/getSections', this.$props.kind)
    },
    saveData() {
      this.$store.dispatch('info/addSection', {
        name: this.sectionName,
        kind: this.sectionKind,
      }).then(() => {
        this.sectionName = ''
        this.addSectionModal = false
        this.$toast.success('Секция добавлена')
      }).catch(() => {
        this.$toast.error('Не удалось добавить секцию')
      })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
